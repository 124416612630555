import React from 'react';
import { Link } from "gatsby";

const Footer = () => {
  return (
    <div className="mt-8 py-4 px-8 text-xs flex items-center text-gray-700">
      <p className="mr-3 pl-7">&copy;&nbsp;2021 The University of Queensland, School of ITEE.</p>
      <Link to="/about">
        <p className="underline">Credit &amp; Project Information</p>
      </Link>
    </div>
  );
};

export default Footer;
