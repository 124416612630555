import * as React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import Graph from "../components/graph";
import Footer from "../components/footer";
import { ChevronRightIcon } from "@heroicons/react/solid";

export default function Home() {
  return (
    <Layout>
      <SEO title="Home" />
      <div className="px-10">
        <div className="mt-8 mb-2 flex flex-wrap gap-y-3 justify-between items-end">
          <h1 className="font-semibold text-2xl pl-3">COVID-19 Twitter Trends</h1>
          <div className="flex flex-wrap items-center text-gray-700 pr-3">
            <p>Force-directed graph layout</p>
            <ChevronRightIcon className="h-6 w-6" />
            <span>Network view</span>
          </div>
        </div>
        <Graph />
      </div>
      <Footer />
    </Layout>
  );
}
