import React, { useEffect, useState, useRef } from "react";
import { useStaticQuery, graphql } from "gatsby";
import * as d3 from "d3";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/solid";

const HEIGHT = 1200;
const WIDTH = 1200;
const MIN_WEEK = 0;
const MAX_WEEK = 14;
const WEEK_GROUPS = 10;

const NODE_MIN_RADIUS = 10;
const NODE_RADIUS_MULTIPLIER = 400;

const SIM_ALPHA_MIN = 0.008;
const SIM_ALPHA_SHOW = 0.03;
const SIM_DRAG_ALPHA = 0.06;
const SIM_DRAG_ALPHA_MIN = 0.001;

interface ITopic { word: string; word_probability: string; t: string; group: string; }
interface ITweet { name: string; id_str: string; t: string; group: string; text: string; created_at: string; }
interface IConnection { t: string; source_group: string; source: string; target_group: string; target: string, value: string; }
interface INode extends d3.SimulationNodeDatum { id: string; value: number; group: number; name: string; }
interface ILink extends d3.SimulationNodeDatum { source: string; target: string; value: number; }
interface IWeek { t: string; time_start: string; time_end: string; }

const Graph: React.FC = () => {
  const { allTopicsCsv: { nodes: allTopics }, allTweetsCsv: { nodes: allTweets },
    allConnectionsCsv: { nodes: allConnections }, allWeeksCsv: { nodes: allWeeks } }: {
      allTopicsCsv: { nodes: ITopic[]; }; allTweetsCsv: { nodes: ITweet[]; };
      allConnectionsCsv: { nodes: IConnection[]; }; allWeeksCsv: { nodes: IWeek[]; };
    } = useStaticQuery(graphql`
    query {
      allTopicsCsv { nodes { word_probability word t group } }
      allTweetsCsv { nodes { id_str name t group text created_at } }
      allConnectionsCsv { nodes { t source_group source target_group target value } }
      allWeeksCsv { nodes { t time_start time_end } }
    }
  `);
  const [week, setWeek] = useState(0);
  const [sliderWeek, setSliderWeek] = useState(0);
  const [topicsData, setTopicsData] = useState(allTopics.filter(e => e.t == `${week}`));
  const [tweetsData, setTweetsData] = useState(allTweets.filter(e => e.t == `${week}`));
  const [connectionsData, setConnectionsData] = useState(allConnections.filter(e => e.t == `${week}`));
  const [currentNode, setCurrentNode] = useState<INode | null>(null);

  const [loading, setLoading] = useState(true);
  const [updating, setUpdating] = useState(false);
  const graphHidden = loading ? `hidden h-full w-full` : `block h-full w-full`;

  const networkGraph = useRef(null);
  const nodeDetail = useRef(null);
  const zoomLevel = useRef(null);
  const tweetList = useRef(null);
  const currentGroup = useRef(null);

  useEffect(() => {
    setTopicsData(allTopics.filter(e => e.t == `${week}`));
    setTweetsData(allTweets.filter(e => e.t == `${week}`));
    setConnectionsData(allConnections.filter(e => e.t == `${week}`));
  }, [week]);

  useEffect(() => {
    setLoading(true);
    const graph = networkGraph.current;
    d3.select(graph).selectChildren().remove();

    // parse data
    const nodes: INode[] = topicsData.map(node => Object.create({
      id: node.word,
      value: node.word_probability,
      group: node.group,
      name: `${node.t}-${node.group}-${node.word}`,
    }));
    let links: ILink[] = connectionsData.map(link => Object.create({
      source: `${link.t}-${link.source_group}-${link.source}`,
      target: `${link.t}-${link.target_group}-${link.target}`,
      value: link.value
    }));

    // Auto add links to the same group (could be slow - move to connections.csv)
    for (let g = 0; g < WEEK_GROUPS; g++) {
      let groupLinks: ILink[] = [];
      let group = topicsData.filter(e => e.group == `${g}`);
      for (let i = 1; i < group.length; i++) {
        groupLinks.push(Object.create({
          source: `${group[0].t}-${g}-${group[0].word}`,
          target: `${group[0].t}-${g}-${group[i].word}`,
          value: "0.2"
        }));
      }
      links = links.concat(groupLinks);
    }

    const simulation = d3.forceSimulation(nodes)
      .force("collision", d3.forceCollide<INode>().radius(d => NODE_MIN_RADIUS + 6 + d.value * NODE_RADIUS_MULTIPLIER).strength(0.9))
      .force("link", d3.forceLink(links).id(d => (d as INode).name).distance(20).strength(0.2))
      .force("charge", d3.forceManyBody().strength(-40))
      .force("x", d3.forceX())
      .force("y", d3.forceY())
      .alphaMin(SIM_ALPHA_MIN);

    const svg = d3.select(graph).append("svg")
      .attr("viewBox", [-WIDTH / 2, -HEIGHT / 2, WIDTH, HEIGHT] as any)
      .style("height", "100%")
      .style("width", "100%")
      .style("max-height", "80vh").style("cursor", "move");

    const g = svg.append("g").attr("class", "graph");

    svg.call((d3.zoom() as any)
      .extent([[0, 0], [WIDTH, HEIGHT]])
      .scaleExtent([1, 8])
      .on("zoom", ({ transform }: any) => {
        d3.select(zoomLevel.current).text(transform.k.toFixed(2));
        g.attr("transform", transform);
      }));

    const link = g.append("g")
      .attr("class", "links")
      .attr("stroke", "#999")
      .attr("stroke-opacity", 0.6)
      .selectAll("line")
      .data(links)
      .join("line")
      .attr("stroke-width", d => Math.sqrt(d.value));

    const color = d3.scaleOrdinal(["#ea4a8b", "#eb3249", "#f16b34", "#f2ad32", "#a2cb52", "#6abe6e", "#33beb5", "#548cc7", "#905da4", "#b659a0"]);

    const node = g.append("g")
      .attr("class", "nodes")
      .selectAll("g")
      .data(nodes)
      .enter()
      .append("g")
      .attr("cursor", "grab")
      .call(drag(simulation) as any)
      .on("click", (_, data) => {
        const node = nodeDetail.current;
        d3.select(node).classed("hidden", false);
        d3.select(graph).classed("w-full", false).classed("w-2/3", true);

        if (data.group.toString() !== d3.select(currentGroup.current).text()) {
          const tweetListRef = tweetList.current;
          d3.select(tweetListRef).selectAll("div").remove();
          d3.select(tweetListRef).selectAll("blockquote").remove();
          tweetsData.filter(e => e.group == data.group.toString()).forEach((item) => {
            const url = `https://twitter.com/${item.name}/status/${item.id_str}`;
            d3.select(tweetListRef)
              .append("blockquote")
              .attr("class", "twitter-tweet mt-2")
              .attr("data-conversation", "none")
              .attr("data-dnt", "true")
              .append("p")
              .attr("class", "text-sm text-gray-800")
              .text(`${item.text} `)
              .append("a")
              .attr("class", "text-blue-500")
              .attr("href", url)
              .text(`from @${item.name} at ${item.created_at}`);
          });
          if ((window as any).twttr) {
            (window as any).twttr.widgets.load();
          }
        }
        setCurrentNode(data);
      });

    const nodeCircle = node.append("circle")
      .attr("stroke", "#fff")
      .attr("stroke-width", 1.5)
      .attr("r", d => NODE_MIN_RADIUS + d.value * NODE_RADIUS_MULTIPLIER)
      .attr("fill", d => color(d.group.toString()));

    nodeCircle.append("title").text(d => d.id);

    const nodeText = node.append("text")
      .attr("fill", "white")
      .attr("text-anchor", "middle")
      .attr("dominant-baseline", "central")
      .attr("font-size", d => `${0.2 + d.value * 8}em`)
      .attr("cursor", "pointer")
      .text(d => d.id);

    simulation.on("tick", () => {
      link.attr("x1", (d: any) => d.source.x)
        .attr("y1", (d: any) => d.source.y)
        .attr("x2", (d: any) => d.target.x)
        .attr("y2", (d: any) => d.target.y);

      nodeCircle.attr("cx", (d: any) => d.x)
        .attr("cy", (d: any) => d.y);

      nodeText.attr("x", (d: any) => d.x)
        .attr("y", (d: any) => d.y);
    });

    // wait until the network settle down
    while (simulation.alpha() > SIM_ALPHA_SHOW) {
      simulation.tick();
    }
    d3.select(zoomLevel.current).text("1.00");

    setLoading(false);
    setUpdating(false);
  }, [topicsData]);

  const drag = (simulation: d3.Simulation<INode, undefined>) => {
    function dragstarted(event: any) {
      if (!event.active) simulation.alphaTarget(SIM_DRAG_ALPHA).restart();
      event.subject.fx = event.subject.x;
      event.subject.fy = event.subject.y;
    }

    function dragged(event: any) {
      event.subject.fx = event.x;
      event.subject.fy = event.y;
    }

    function dragended(event: any) {
      if (!event.active) simulation.alphaTarget(0).alphaMin(SIM_DRAG_ALPHA_MIN);
      event.subject.fx = null;
      event.subject.fy = null;
    }

    return d3.drag()
      .on("start", dragstarted)
      .on("drag", dragged)
      .on("end", dragended);
  };

  return (<>
    <div className="relative w-full shadow-inner flex flex-col bg-gray-50 h-full" style={{ minHeight: "20vh", maxHeight: "80vh" }}>
      {loading && (
        <div className="absolute top-0 left-0 p-4 flex items-center justify-center w-full h-full">
          <svg className="animate-spin ml-1 mr-3 h-5 w-5 text-gray-800" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
          </svg>
          <span>Initialising...</span>
        </div>
      )}
      {updating && (
        <div className="absolute top-0 left-0 flex items-center justify-center w-full h-full">
          <div className="px-4 py-3 flex items-center justify-center rounded-lg" style={{ backgroundColor: "rgba(255, 255, 255, 0.9)" }}>
            <svg className="animate-spin ml-1 mr-3 h-5 w-5 text-gray-800" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
              <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
              <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
            <span>Updating data...</span>
          </div>
        </div>
      )}

      <div className="flex" style={{ maxHeight: "80vh" }}>
        <div className={graphHidden} ref={networkGraph} />
        <div className="hidden w-1/3 px-6 flex flex-col bg-gray-100 overflow-scroll shadow-inner" ref={nodeDetail}>
          <h2 className="pt-6 pb-3 font-semibold text-2xl">{currentNode?.id}</h2>
          <hr />
          <p className="pt-3 pb-2 text-sm"><span className="font-medium">Group:&nbsp;</span><span className="text-gray-800" ref={currentGroup}>{currentNode?.group}</span></p>
          <p className="pb-3 text-sm"><span className="font-medium">Trend:&nbsp;</span>
            <span className="text-gray-800">{currentNode ? (currentNode.value * NODE_RADIUS_MULTIPLIER).toFixed(1) : ""}&nbsp;(p = {currentNode?.value})</span></p>
          <hr />
          <p className="pt-3 font-medium">Related Tweets</p>
          <div className="tweet-list" ref={tweetList} />
        </div>
      </div>

      <div className="absolute bottom-0 left-0 flex items-center justify-center text-xs pl-6 pb-2">
        <p className="text-gray-300">Zoom: <span ref={zoomLevel}>1.00</span>x</p>
      </div>
    </div>
    <div className="bg-gray-100 py-2 px-6 shadow-inner flex flex-wrap justify-between items-center">
      <p className="mr-6 font-semibold flex items-center"><span>Week {sliderWeek}</span><span className="text-sm text-gray-700 font-medium">&nbsp;&nbsp;&nbsp;{allWeeks[sliderWeek].time_start.slice(0, -9).replace(/-/g, "/")} - {allWeeks[sliderWeek].time_end.slice(0, -9).replace(/-/g, "/")}</span></p>
      <div className="flex flex-wrap justify-between flex-grow items-center">
        <button className="flex items-center transition duration-300 hover:text-gray-500 flex-grow-0" onClick={() => { if (week > MIN_WEEK) { setSliderWeek(week - 1); setUpdating(true); setWeek(week - 1); } }}>
          <ChevronLeftIcon className="h-6 w-6 mr-1" />
        </button>
        <div className="slidecontainer flex items-center flex-grow">
          <input className="w-full slider" type="range" min={MIN_WEEK} max={MAX_WEEK} step="1" value={sliderWeek}
            onMouseUp={e => { if (Number.parseInt(e.currentTarget.value) !== week) { setUpdating(true); setWeek(Number.parseInt(e.currentTarget.value)); } }}
            onTouchEnd={e => { if (Number.parseInt(e.currentTarget.value) !== week) { setUpdating(true); setWeek(Number.parseInt(e.currentTarget.value)); } }}
            onChange={e => setSliderWeek(Number.parseInt(e.target.value))} />
        </div>
        <button className="flex items-center transition duration-300 hover:text-gray-500 flex-grow-0" onClick={() => { if (week < MAX_WEEK) { setSliderWeek(week + 1); setUpdating(true); setWeek(week + 1); } }}>
          <ChevronRightIcon className="h-6 w-6 ml-1" />
        </button>
      </div>
    </div>
  </>);
};

export default Graph;
